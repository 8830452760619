import { PARTNER_01, PARTNER_02 } from "../../constants/images/images.c";

const Partners = () => {
  const partners = [
    {
      name: "Jorge Ferreira, CFA",
      title: "Diretor de Consultoria",
      image: PARTNER_01,
      description:
        "Economista, mestre em Finanças Corporativas (Unisinos) e CFA charterholder, possui experiência de 15 anos no mercado financeiro e de capitais. Sócio fundador da Ostrya Investimentos e Ostrya capital. Professor de finanças, economia e gestão em cursos de Graduação, MBA e Mestrado, na Unisinos. Professor de Economia na CFA Society Brazil. Já lecionou sobre investimentos em curso de formação de reguladores na Comissão de Valores Mobiliários (CVM). Trabalha com crédito corporativo desde 2016. Foi Superintendente Executivo dos Comitês de Crédito do Banrisul entre 2020 e 2023. Participou da análise, concessão e restruturação de crédito para as maiores empresas do Sul do país. Autor do livro “Análise de Investimentos e Mercado de Capitais”, utilizado em cursos de graduação. Autor de artigo acadêmico sobre o mercado financeiro publicado na Revista Contabilidade & Finanças/USP e citado em 13 países. Consultor de Títulos e Valores Mobiliários e Gestor de Carteiras (CVM). Analista de títulos e valores mobiliários (APIMEC).",
    },
    {
      name: "Geile Aline Lüttjohann",
      title: "Diretora de Compliance",
      image: PARTNER_02,
      description:
        "Bacharel em Direito pela UNISINOS, possui especialização em Direito Empresarial e Direito Bancário (Verbo Jurídico), Possui certificações em Gestão Financeira e Desempenho Empresarial (Insper), Contabilidade Financeira (FGV), Crédito e Risco (FGV), Falências e Recuperação de Empresas (FGV) e Sustentabilidade Corporativa, Regulação e Transparência em ESG (FGV). Atualmente cursando Pós-Graduação em Contabilidade, Gestão Financeira e Fiscal, Pós-Graduação em Gestão Financeira e MBA em Gestão de Projetos e Metodologias Ágeis. Possui mais de 15 anos de experiência no mercado bancário, com foco na análise de empresas e concessão de crédito. Atua como advogada desde 2015, com experiência em contratos bancários e empresariais, além de atuação direta em processos de recuperação judicial de empresas e assessoria em processos de fusões e aquisições.",
    },
  ];

  return (
    <section className="py-5 bg-light">
      <div className="container">
        <h2 className="text-center mb-5 display-4">Sócios Fundadores</h2>
        <div className="row">
          {partners.map((partner, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card shadow-sm h-100 text-center">
                <div className="d-flex justify-content-center mt-3">
                  <img
                    src={partner.image}
                    alt={partner.name}
                    className="rounded-circle"
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                      border: "3px solid #ddd",
                    }}
                  />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{partner.name}</h5>
                  <h6 className="text-muted">{partner.title}</h6>
                  <p className="card-text">{partner.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
