/**
 * ==============================================
 * FLOAT-ICONS
 * ==============================================
 */

/**
 * ==============================================
 * HEADER SECTION
 * ==============================================
 */
export const LOGO_IMAGE = require("../../assets/img/logo/logo.png");

/**
 * ==============================================
 * HERO SECTION
 * ==============================================
 */
export const HERO_IMAGE = require("../../assets/img/hero/hero-image.png");
export const SERVICE_ICON_01 = require("../../assets/img/hero/service-01.png");
export const SERVICE_ICON_02 = require("../../assets/img/hero/service-02.png");
export const SERVICE_ICON_03 = require("../../assets/img/hero/service-03.png");

/**
 * ==============================================
 * ABOUT US SECTION
 * ==============================================
 */
export const ABOUT_IMAGE = require("../../assets/img/about/about-image.png");
export const ABOUT_FLOAT_HORIZONTAL_ICON = require("../../assets/img/about/float-icon-horizontal.png");

/**
 * ==============================================
 * PLANNING SECTION
 * ==============================================
 */
export const PLANNING_IMAGE = require("../../assets/img/planning/planning-image.png");
export const PLANNING_VERTICAL_ICON = require("../../assets/img/planning/planning-vertical-icon.png");
export const PLANNING_ICON_01 = require("../../assets/img/planning/planning-icon-01.png");
export const PLANNING_ICON_02 = require("../../assets/img/planning/planning-icon-02.png");
export const PLANNING_ICON_03 = require("../../assets/img/planning/planning-icon-03.png");

/**
 * ==============================================
 * EVALUATION SECTION
 * ==============================================
 */
export const EVALUATION_IMAGE = require("../../assets/img/evaluation/evaluation-image.jpeg");

/**
 * ==============================================
 * MENTORING SECTION
 * ==============================================
 */
export const MENTORING_IMAGE = require("../../assets/img/mentoring/mentoring-image.png");

/**
 * ==============================================
 * WHY CHOOSE US SECTION
 * ==============================================
 */
export const WHY_CHOOSE_US_IMAGE = require("../../assets/img/why-choose-us/why-choose-us-image.png");

/**
 * ==============================================
 * IMPORTANCE SECTION
 * ==============================================
 */
export const IMPORTANCE_IMAGE = require("../../assets/img/importance/importance.png");

/**
 * ==============================================
 * MULTI FAMILY SECTION
 * ==============================================
 */
export const MULTI_FAMILY_IMAGE = require("../../assets/img/multi-family/multi-family.jpg");

/**
 * ==============================================
 * INSIGHTS SECTION
 * ==============================================
 */
export const INSIGHT_CARD_01 = require("../../assets/img/Investment/investments-01.png");
export const INSIGHT_CARD_02 = require("../../assets/img/Investment/investments-02.png");
export const INSIGHT_CARD_03 = require("../../assets/img/Investment/investments-03.png");
export const INSIGHT_CARD_04 = require("../../assets/img/Investment/investments-04.png");
export const INSIGHT_CARD_05 = require("../../assets/img/Investment/investments-05.png");

/**
 * ==============================================
 * METHODOLOGY SECTION
 * ==============================================
 */
export const METHODOLOGY_IMAGE = require("../../assets/img/methodology/methodology-image.png");

/**
 * ==============================================
 * PARTNERS SECTION
 * ==============================================
 */
export const PARTNER_01 = require("../../assets/img/partners/partner-01.png")
export const PARTNER_02 = require("../../assets/img/partners/partner-02.png");